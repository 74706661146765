import { ActionCreatorsMapObject } from 'redux'
import { all, call, put, SagaReturnType } from 'redux-saga/effects'
import { createModule, EffectParam, STATUS } from 'redux-acron'
import dayjs from 'dayjs'
import config from '@config'
import access from '@utils/access'
import authModule from './AuthModule'
import accountModule from './AccountModule'
import api from '@services/api'
import useClientConfig from 'config/clients/useClientConfig'
import { getFilterField, getGeoLocationFields, getLogicalLocationFields } from '@utils/ReferenceUtils'

export interface SurveysCountInfo {
  answered: number
  not_handled: number
  in_progress: number
  notHandled: number
  inProgress: number
  closed: number
  fake: number
  invalid: number
}

export interface AlertsCountInfo {
  not_handled: number
  closed: number
  in_process: number
  wows: number
}

export interface NotificationsCountInfo {
  answeredCount: number | null
  clickedCount: number | null
  notificationsCount: number | null
  openedCount: number | null
  processedCount: number | null
  queuedCount: number | null
  sentCount: number | null
  errorCount: number | null
}

export type AppDataState = {
  title: string
  menuItems: MenuType[]
  specialFeats: object | null
  filters: {
    fromDate: string
    toDate: string
    logicalDistributions: string | null
    geographicDistributions: string | null
    cluster: string | null
    criticalMoments: string | null
    valueClient: boolean | null
  }
  filtersData: {
    logicalDistributions: any[]
    geographicDistributions: any[]
    geographicList: any[]
    criticalMoments: any[]
    channels: any[]
    clusters: any[]
    dynamicReportsFields: any[]
    workflows: any[]
  }

  // Survey counters
  surveysCount: SurveysCountInfo | null
  loadingSurveysCount: boolean

  // Alerts counters
  alertsCount: AlertsCountInfo
  loadingAlertsCount: boolean
  countersRange: number

  // Notification counters
  notificationsCount: NotificationsCountInfo | null
  loadingNotificationsCount: boolean
  notificationsCountRange: number
}

const getFiltersDefaultFromDate = () => {
  const { getFilterFromDateSubstract } = useClientConfig()
  return dayjs().subtract(getFilterFromDateSubstract(), 'month').format('YYYY-MM-DD')
}

const getFiltersDefaultToDate = () => {
  const { getFilterToDateSubstract } = useClientConfig()
  return dayjs().subtract(getFilterToDateSubstract(), 'month').format('YYYY-MM-DD')
}

const getDefaultCriticalMoment = (payload: any) => {
  const { getUseDefaultCriticalMoment } = useClientConfig()

  if (getUseDefaultCriticalMoment() && payload?.data?.defaultCriticalMoment?.id !== undefined) {
    return payload?.data?.defaultCriticalMoment?.id
  }

  return payload.data.criticalMoments && payload.data.criticalMoments[0]
    ? payload.data.criticalMoments[0].id
    : undefined
}

/**
 * AppData Module
 * reducer/sagas/actions
 */
const AppDataModule = createModule<AppDataState>({
  name: 'appData',
  state: {
    title: '',
    menuItems: [],
    specialFeats: {},
    filters: {
      fromDate: getFiltersDefaultFromDate(),
      toDate: getFiltersDefaultToDate(),
      logicalDistributions: null,
      geographicDistributions: null,
      cluster: null,
      criticalMoments: null,
      valueClient: null,
    },

    filtersData: {
      logicalDistributions: [],
      geographicDistributions: [],
      geographicList: [],
      criticalMoments: [],
      channels: [],
      clusters: [],
      dynamicReportsFields: [],
      workflows: []
    },

    surveysCount: null,
    loadingSurveysCount: false,

    alertsCount: {
      not_handled: 0,
      closed: 0,
      in_process: 0,
      wows: 0,
    },
    loadingAlertsCount: false,
    countersRange: 30,

    // Notification counters
    notificationsCount: {
      answeredCount: null,
      clickedCount: null,
      notificationsCount: null,
      openedCount: null,
      processedCount: null,
      queuedCount: null,
      sentCount: null,
      errorCount: null,
    },
    loadingNotificationsCount: false,
    notificationsCountRange: 7
  },

  handlers: {
    setTitle: (state, { payload }: { payload: { title: string } }) => {
      return state.merge({
        title: payload.title,
      })
    },

    setMenuItems: (
      state,
      { payload }: { payload: { items: Array<MenuType> } }
    ) => {
      return state.merge({
        menuItems: payload.items,
      })
    },

    setFiltersData: (state, { payload }) => {
      return state.merge({
        filtersData: payload,
      })
    },

    setFilters: (state, { payload }) => {
      return state.merge({
        filters: payload,
      })
    },

    getCounters: (state, { payload, status }) => {
      if (status === STATUS.PENDING_STATUS) {
        return state.merge({
          notificationsCount: null,
          loadingNotificationsCount: true,
          notificationsCountRange: payload.range
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          notificationsCount: payload.data,
          loadingNotificationsCount: false,
          notificationsCountRange: payload.range
        })
      }

      return state
    },

    getSurveyCount: (state, { payload, status }) => {
      if (status === STATUS.PENDING_STATUS) {
        return state.merge({
          surveysCount: null,
          loadingSurveysCount: true
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        const data = payload?.data?.[0]?.value
        console.log(data)
        let surveyResponses = {}

        /*Object.keys(payload.data ?? {})
          ?.filter((item: string) => item.startsWith(prefix))
          ?.forEach((curr: any) => {
            const key = curr.replaceAll(prefix, '') ?? curr
            surveyResponses[key] = payload?.data?.[curr]
          })*/

        Object.keys(data ?? {})
          ?.forEach((curr: any) => {
            const posfix = 'Count'
            const key = curr.replaceAll(posfix, '') ?? curr
            surveyResponses[curr] = data?.[curr]
            surveyResponses[key] = data?.[curr]
          })

        return state.merge({
          loadingSurveysCount: false,
          surveysCount: {
            answered: data?.answeredCount,
            ...surveyResponses
          }
        })
      }

      return state
    },

    getAlertsCount: (state, { payload, status }) => {
      if (status === STATUS.PENDING_STATUS) {
        return state.merge({
          loadingAlertsCount: true,
          countersRange: payload.range,
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          loadingAlertsCount: false,
          alertsCount: payload.data,
          countersRange: payload.range,
        })
      }
      return state
    },

    [authModule.actionsTypes.signin]: (
      state,
      { payload, status }: ActionPayload<{ data: UserAccount }>
    ) => {
      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          specialFeats: config.specialFeats?.[payload.data.tenantId],
          menuItems: access.pages.getMenuItems(
            payload.data.authorities,
            payload.data?.userProfiles?.[0]?.config.access
          ),
          filtersData: {
            logicalDistributions: payload.data.criticalMoments,
            geographicDistributions: payload.data.geoLocations,
            criticalMoments: payload.data.criticalMoments,
            channels: payload.data.channels,
            clusters: payload.data.clusters,
            dynamicReportsFields: payload.data.dynamicReportsFields,
            workflows: payload.data.workflows,
          },
          filters: {
            ...state.filters,
            logicalDistributions:
              payload.data.logicalLocations && payload.data.logicalLocations.length > 0
                ? payload.data.logicalLocations.map(logical => logical.id)
                : [],
            geographicDistributions:
              payload.data.geoLocations && payload.data.geoLocations.length > 0
                ? payload.data.geoLocations.map(geo => geo.id)
                : [],
            criticalMoments: [getDefaultCriticalMoment(payload)],
            cluster: [
              payload.data.clusters && payload.data.clusters[0]
                ? payload.data.clusters[0].id
                : undefined,
            ]
          },
        })
      }
      return state
    },

    [accountModule.actionsTypes.getInfo]: (
      state,
      { payload, status }: ActionPayload<{ data: UserAccount }>
    ) => {
      if (status === STATUS.SUCCESS_STATUS) {
        const filters =
          payload.data.authorities.indexOf('ROLE_ADMIN') === -1 &&
            payload.data.authorities.indexOf('ROLE_ROOT') === -1
            ? {
              logicalDistributions: payload.data.logicalLocations
                && payload.data.logicalLocations.map(logical => logical.id),
              geographicDistributions: payload.data.geoLocations
                && payload.data.geoLocations.map(geo => geo.id),
              criticalMoments: [getDefaultCriticalMoment(payload)],
              clusters: payload.data.clusters.length > 0
                ? [payload.data.clusters[0].id] : [],
            }
            : {}

        return state.merge({
          specialFeats: config.specialFeats?.[payload.data.tenantId],
          menuItems: access.pages.getMenuItems(
            payload.data.authorities,
            payload.data?.userProfiles?.[0]?.config.access
          ),
          filtersData: {
            logicalDistributions: payload.data.logicalLocations,
            geographicDistributions: payload.data.geoLocations,
            criticalMoments: payload.data.criticalMoments,
            channels: payload.data.channels,
            clusters: payload.data.clusters,
            dynamicReportsFields: payload.data.dynamicReportsFields,
            workflows: payload.data.workflows,
          },
          filters: {
            ...state.filters,
            ...filters,
          },
        })
      }

      return state
    },
  },
  effects: {
    getCounters: {
      *callback(
        actions: ActionCreatorsMapObject,
        {
          payload,
        }: EffectParam<{
          filters: FiltersUI
          filtersData: Record<string, any>
          range: number
        }>
      ) {
        type ResponseType = SagaReturnType<
          typeof api.surveysResponse.lcmCounters
        >

        //console.log('yendo a buscar lcmCounters')
        //console.log(payload)

        // Geo locations
        /*const geoCodes = getGeoLocationFields(
          payload.filtersData.geographicDistributions,
          payload.filters.geographicDistributions,
          'name'
        )
        const geoLeaves = getFilterField(
          payload.filtersData.geographicDistributions,
          payload.filters.geographicDistributions,
          'name'
        )*/
        //const geos = [...geoCodes, ...geoLeaves]

        // Logical locations
        /*const logicalCodes = getLogicalLocationFields(
          payload.filtersData.logicalDistributions,
          payload.filters.logicalDistributions,
          'name'
        )
        const logicalLeaves = getFilterField(
          payload.filtersData.logicalDistributions,
          payload.filters.logicalDistributions,
          'name'
        )*/
        //const logicals = [...logicalCodes, ...logicalLeaves]

        /*const cmCode = getCriticalMomentFields(payload.filtersData.criticalMoments,
          payload.filters.criticalMoments, 'name')*/
        const lcmFilters = {
          ...payload.filters,
          //workflowName: cmCode && cmCode.length > 0 ? cmCode[0] : undefined,
          //geoLocations: geos && geos.length > 0 ? geos.join(',') : undefined,
          //logicalLocations: logicals && logicals.length > 0 ? logicals.join(',') : undefined,
          /*criticalMoments: undefined*/
        }
        const lcmResponse: ResponseType = yield call(api.surveysResponse.lcmCounters, lcmFilters)

        if (lcmResponse.ok) {
          type ResponseType = SagaReturnType<
            typeof api.surveysResponse.lmtCounters
          >
          const lmtResponse: ResponseType = yield call(
            api.surveysResponse.vocCounters,
            payload.filters
          )

          if (lmtResponse.ok) {
            //console.log('SETEANDO DATA STATUS!!!!!!!!')
            //console.log(lmtResponse)
            //console.log(lcmResponse)
            yield put(actions.getCounters(
              {
                range: payload.range,
                data: {
                  answeredCount: lmtResponse.data.answered,
                  clickedCount: lcmResponse.data.resume.clicked,
                  notificationsCount: lcmResponse.data.resume.notifications,
                  openedCount: lcmResponse.data.resume.opened,
                  processedCount: lcmResponse.data.resume.processed,
                  queuedCount: lcmResponse.data.resume.queued,
                  sentCount: lcmResponse.data.resume.sent,
                  errorCount: lcmResponse.data.resume.error,
                },
              },
              STATUS.SUCCESS_STATUS
            ))
          }
        }
      },
    },
    getSurveyCount: {
      *callback(
        actions: ActionCreatorsMapObject,
        { payload }: EffectParam<{ filters: object; range: number }>
      ) {
        type ResponseType = SagaReturnType<typeof api.surveysResponse.vocCounters>
        const response: ResponseType = yield call(
          api.surveysResponse.vocCounters,
          payload.filters
        )

        if (response.ok) {
          yield put(
            actions.getSurveyCount(
              {
                data: response.data,
              },
              STATUS.SUCCESS_STATUS
            )
          )
        }
      },
    },

    getAlertsCount: {
      *callback(
        actions: ActionCreatorsMapObject,
        { payload }: EffectParam<{ filters: object; range: number }>
      ) {
        type DataResponseType = SagaReturnType<typeof api.alerts.list>
        const responses: DataResponseType[] = yield all([
          api.alerts.list(0, 1, {
            ...payload.filters,
            alertStatusInclude: 'NOT_HANDLED',
            alertTypeInclude: 'INCIDENT,RESCUE,ABANDON',
          }),

          api.alerts.list(0, 1, {
            ...payload.filters,
            alertStatusInclude: 'IN_PROCESS',
          }),

          api.alerts.list(0, 1, {
            ...payload.filters,
            alertStatusInclude: 'CLOSED',
          }),

          api.alerts.list(0, 1, {
            ...payload.filters,
            alertTypeInclude: 'WOW',
          }),
        ])

        const responseOk = responses.reduce((result, value) => {
          result = result && value.ok
          return result
        }, true)

        if (responseOk) {
          yield put(
            actions.getAlertsCount(
              {
                range: payload.range,
                data: {
                  not_handled: Number(responses[0].headers?.['x-total-count']),
                  in_process: Number(responses[1].headers?.['x-total-count']),
                  closed: Number(responses[2].headers?.['x-total-count']),
                  wows: Number(responses[3].headers?.['x-total-count']),
                },
              },
              STATUS.SUCCESS_STATUS
            )
          )
        }
      },
    },
  },
})

export default AppDataModule
