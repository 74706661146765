import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const CJ_JAC_ORDER = {
  10365: 0, // Recepción,
  10366: 1, // Atención y amabilidad,
  10367: 2, // Conocimiento,
  10368: 3, // Instalaciones,
  10369: 4, // Entrega,
  10372: 0, // NPS - Mantenimiento
  10373: 1, // Atención y amabilidad,
  10374: 2, // Recepción,
  10375: 3, // Seguimiento
  10376: 4, // Entrega
  10377: 5, // Tasa de retorno
}

export const getCmID = (code: string): number => {
  if (code === 'venta') return 9951
  else if (code === 'servicio' || code === 'postventa') return 9952
  else return 29
}

export const jacCustomerJourneyMapper = (data: any, extra: any) => {
  return data.map((item: any) => {
    return {
      ...item,
      //label: 'widgets.indicatorId.' + item.groupId,
      weight: CJ_JAC_ORDER[item.groupId],
    }
  })// @ts-ignore
    .asMutable()
    .sort((item1: any, item2: any) => item1.weight - item2.weight)
}

export const jacGaugeCountNumberMapper = (data: any) => {
  //console.log('Atención mapper!!!')
  //console.log(data)

  const total = data?.reduce((acc: any, curr: any) => acc + curr?.count, 0) ?? 0
  const satisfecho = data?.find((item: any) => item?.groupId === 4)?.value ?? 0
  const muySatisfecho = data?.find((item: any) => item?.groupId === 5)?.value ?? 0
  const value = total > 0 
  ? (satisfecho + muySatisfecho / total) 
  : (satisfecho + muySatisfecho)

  //console.log(`valores satisfecho=${satisfecho} muySatisfecho=${muySatisfecho} total=${total} res=${value}`)

  return value
}

export const commonWidgets: CommonWidget = {
  jacGaugeSingleton: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'gauge-generic',
      type: 'gauge',
      fillMode: '',
      color: '#33b9ce',
      showValues: true,
      showHistoric: false,
      maxValue: 100,
      decimals: 1,
      center: ['50%', '50%'],
      radius: '90%',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupId', 'count'],
          value: 'value',
          dataType: 'list',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  ...widgetsLibrary
}
