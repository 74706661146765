import React from 'react'
import { Layout, Space } from 'antd'

import './PublicLayout.less'

type PropsType = {
  children: Array<React.ReactNode>
}

const PublicLayout  = ({ children, }: PropsType): JSX.Element => {
  
  return (
    <Layout className="public-layout">
      <Layout.Content>
        {children}
      </Layout.Content>

      <Layout.Footer>
        <span className="copyright">
          Copyright © 2024 Clientship. All rights reserved. v1
        </span>

        <div className="flex-1"/>
        <div className="legal-links">
          <Space size={30} align="center">
            <a href="!#">Legal</a>
            <span>|</span>
            <a href="!#">Politicas de Privacidad</a>
          </Space>
        </div>
      </Layout.Footer>
    </Layout>
  )
}

export default PublicLayout
