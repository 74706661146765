import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_SCALE_5_COLORS, commonWidgets, getProfuturoAtencion, getProfuturoNpsAcumulado } from "../commonWidgetsProfuturo";

export default {
    title: 'Acompañamiento Dashboard',
    description: 'Acompañamiento Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        {
            title: 'Principales Indicadores',
            position: [0, 0],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getProfuturoNpsAcumulado({
            position: [0, 1],
            size: [2, 2],
            indicator: 'cuest_afore_v_ll_pregunta_2'
        }),
        getProfuturoAtencion({
            position: [2, 1],
            size: [2, 2],
            indicator: 'cuest_afore_v_ll_pregunta_13',
            helpText: 'La buena atención por parte del personal',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de Sentimientos',
            position: [0, 3],
            size: [4, 4],
            indicator: 'SENTIMENT'
        }),
    ]
} as DasboardConfig