import { getCommonWidget } from "../../dashWidgetHelper";
import { commonWidgets, getProfuturoAtencion, getProfuturoFacilidad, getProfuturoNpsAcumulado, 
        getProfuturoNpsPorMes, getProfuturoSatisfaccion, getProfuturoCustomerJourney } from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
  {
    title: 'Principales Indicadores',
    position: [0, 0],
    size: [4, 1],
     type: 'label',
     config: { color: '#B7AADC' },
  },
  getProfuturoNpsAcumulado({
    position: [0, 1],
    size: [1, 2],
    indicator: 'prestamo_sofom_encuesta_pregunta_13'
  }),
  getProfuturoNpsPorMes({
    position: [1, 1],
    size: [1, 2],
    indicator: 'prestamo_sofom_encuesta_pregunta_13',
  }),
  getProfuturoSatisfaccion({
    position: [2, 1],
    size: [1, 2],
    indicator: 'prestamo_sofom_encuesta_pregunta_2',
    helpText: '¿Qué tan satisfecho estás con la atención recibida por parte de tu asesor patrimonial Profuturo durante la contratación de tu préstamo?'
  }),
  getProfuturoFacilidad({
    position: [3, 1],
    size: [1, 2],
    indicator: 'prestamo_sofom_encuesta_pregunta_3',
    helpText: '¿Qué tan fácil fue el proceso de contratación del préstamo?'
  }),
  getProfuturoCustomerJourney({
      id: 'cj-ventaPrestamosSOFOM',
      position: [0, 3],
      size: [3, 2],
      indicators: [ { name: 'prestamo_sofom_encuesta_pregunta_2', formula: 'count-number' }, //csat
                    { name: 'prestamo_sofom_encuesta_pregunta_3', formula: 'count-number' }, //facilidad
                    { name: 'prestamo_sofom_encuesta_pregunta_13', formula: 'nps-10' }, //nps
      ],
      title: 'Customer Journey: Top two box',
      
  }),
  {
    title: 'Atributos de experiencia',
    position: [0, 5],
    size: [4, 1],
     type: 'label',
     config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Me sentí seguro al compratir mi información y datos durante el proceso',
    position: [0, 6],
    size: [4, 1],
    helpText: 'Pensando la contratación del préstamo y la atención recibida por el asesor, por favor señala que tan de acuerdo estás con cada una de las frases:',
    indicator: 'prestamo_sofom_encuesta_pregunta_8'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Me sentí acompañado por el asesor patrimonial Profuturo',
    position: [0, 7],
    size: [4, 1],
    helpText: 'Pensando la contratación del préstamo y la atención recibida por el asesor, por favor señala que tan de acuerdo estás con cada una de las frases:',
    indicator: 'prestamo_sofom_encuesta_pregunta_9'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Recibí la información que necesitaba de forma clara y precisa',
    position: [0, 8],
    size: [4, 1],
    helpText: 'Pensando la contratación del préstamo y la atención recibida por el asesor, por favor señala que tan de acuerdo estás con cada una de las frases:',
    indicator: 'prestamo_sofom_encuesta_pregunta_10'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Recibí la asesoría necesaria para que pudiera tomar la mejor decisión',
    position: [0, 9],
    size: [4, 1],
    helpText: 'Pensando la contratación del préstamo y la atención recibida por el asesor, por favor señala que tan de acuerdo estás con cada una de las frases:',
    indicator: 'prestamo_sofom_encuesta_pregunta_11'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Las explicaciones acerca del pago y los montos a descontar fueron precisas y exactas',
    position: [0, 10],
    size: [4, 1],
    helpText: 'Pensando la contratación del préstamo y la atención recibida por el asesor, por favor señala que tan de acuerdo estás con cada una de las frases:',
    indicator: 'prestamo_sofom_encuesta_pregunta_12'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'El proceso de contratación y la velocidad de la entrega del dinero fueron adecuadas',
    position: [0, 11],
    size: [4, 1],
    helpText: 'Pensando la contratación del préstamo y la atención recibida por el asesor, por favor señala que tan de acuerdo estás con cada una de las frases:',
    indicator: 'prestamo_sofom_encuesta_pregunta_6'
  }),
  {
    title: 'Análisis de datos',
    position: [0, 12],
    size: [4, 1],
     type: 'label'
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCount',
    title: 'Atención',
    helpText: '*¿Por qué calificaste de esa manera la atención que recibiste?',
    position: [0, 13],
    size: [4, 2],
    indicator: 'prestamo_sofom_encuesta_pregunta_4'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCount',
    title: 'Facilidad: clientes insatisfechos',
    helpText: '*¿Por qué no te resultó fácil?',
    position: [0, 15],
    size: [4, 2],
    indicator: 'prestamo_sofom_encuesta_pregunta_5'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    title: 'Análisis de Sentimientos',
    position: [0, 17],
    size: [4, 4],
    indicator: 'SENTIMENT'
  }),
]} as DasboardConfig
