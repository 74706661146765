import { getCommonWidget } from "../../dashWidgetHelper";
import {
  PRODUTURO_PIE_COLORS, PRODUTURO_SCALE_4_COLORS, PRODUTURO_SCALE_5_COLORS, commonWidgets, getProfuturoAtencion,
  getProfuturoFacilidad, getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion, getProfuturoNpsConsar, getProfuturoCustomerJourney,
  PRODUTURO_SCALE_3_COLORS,
  GENERAL_INDICATORS,
  getNpsPorEjecutivoTable
}
  from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'Principales Indicadores',
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getProfuturoNpsAcumulado({
      size: [1, 2],
      indicator: 'sucursal_pregunta_22'
    }),
    getProfuturoNpsPorMes({
      size: [1, 2],
      indicator: 'sucursal_pregunta_22'
    }),

    getProfuturoSatisfaccion({
      size: [1, 2],
      indicator: 'sucursal_pregunta_2',
      helpText: '¿Qué tan satisfecho estás con tu visita a la sucursal?'
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'countNumberSemicircle',
      title: 'Satisfacción tiempo de espera',
      size: [1, 2],
      indicator: 'sucursal_pregunta_4',
      helpText: '¿Qué tan satisfecho estás con el tiempo de espera para ser atendido?',
      historic: {
        type: 'line',
        formula: "wavg",
        formulaConfig: "avgmax-10"
      }
    }),
    getProfuturoAtencion({
      size: [1, 2],
      indicator: 'sucursal_pregunta_9',
      helpText: 'Ahora vamos a hablar del asesor que te atendió en tu última visita a la sucursal, ¿cómo fue tu experiencia?'
    }),
    getProfuturoFacilidad({
      size: [1, 2],
      indicator: 'sucursal_pregunta_5',
      title: 'Facilidad cita',
      helpText: '¿Qué tan sencillo te resultó agendar una cita para asistir a la sucursal?'
    }),
    getProfuturoNpsConsar({
      indicator: 'sucursal_pregunta_26',
      size: [1, 2],
      title: 'NPS CONSAR',
      helpText: '¿Qué tan probable es que recomiendes el servicio de Sucursal con amigos y/o familiares?'
    }),
    {
      title: 'Motivadores y quiebres',
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Satisfacción: clientes insatisfechos',
      size: [2, 2],
      indicator: 'sucursal_pregunta_3',
      helpText: 'Señalaste que estás insatisfecho con la visita, ¿nos puedes contar por qué?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericLarge',
      title: 'Quiebres en la facilidad',
      indicator: 'sucursal_pregunta_6',
      size: [2, 2],
      helpText: '¿Por qué razones te resultó difícil agendar una cita?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Clientes sin cita',
      size: [2, 2],
      indicator: 'sucursal_pregunta_7',
      helpText: '¿Por qué acudiste a la sucursal sin agendar una cita previa?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Probabilidad de agendar cita',
      helpText: 'La próxima vez que necesites asistir a una sucursal, ¿cuál es la probabilidad de que agendes una cita?',
      size: [2, 2],
      indicator: 'sucursal_pregunta_8',
      extraConfigs: {
        colors: PRODUTURO_SCALE_4_COLORS
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Atención: clientes insatisfechos',
      helpText: 'Señalaste que estás insatisfecho con la atención del asistente, ¿nos puedes contar por qué? ',
      size: [2, 2],
      indicator: 'sucursal_pregunta_10',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMediumSiNo',
      title: 'Resolución del Trámite',
      helpText: ' En tu última visita, ¿Se resolvió tu trámite?',
      size: [2, 2],
      indicator: 'sucursal_pregunta_27',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Atención que recibiste del asesor',
      helpText: 'De acuerdo a la atención que recibiste del asesor, ¿qué frase es con la que concuerdas más?',
      size: [2, 2],
      indicator: 'sucursal_pregunta_21',
      extraConfigs: {
        colors: PRODUTURO_SCALE_4_COLORS,
      }
    }),

    getProfuturoCustomerJourney({
      id: 'cj-sucursal',
      title: 'Customer Journey: Top two box',
      size: [4, 2],
      indicators: [
        { name: 'sucursal_pregunta_2', formula: 'count-number' }, //csat
        { name: 'sucursal_pregunta_4', formula: 'count-number' }, //tiempo espera
        { name: 'sucursal_pregunta_9', formula: 'count-number' }, //atencion
        { name: 'sucursal_pregunta_22', formula: 'nps-10' }, //nps
      ],
    }),
    {
      title: 'Atributos de experiencia',
      size: [4, 1],
      type: 'label',
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La amabilidad y calidez con la que me atendió',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      size: [4, 1],
      indicator: 'sucursal_pregunta_12'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Los conocimientos y claridad acerca de la solicitud / trámite.',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      size: [4, 1],
      indicator: 'sucursal_pregunta_13'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'El compromiso e interés que se mostró para resolver mi petición.',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      size: [4, 1],
      indicator: 'sucursal_pregunta_14'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La asesoría que me dieron (orientación) para que tomara una decisión informada.',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      size: [4, 1],
      indicator: 'sucursal_pregunta_15'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La imagen del asesor refleja profesionalismo.',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      size: [4, 1],
      indicator: 'sucursal_pregunta_16'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La ubicación de la sucursal.',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      size: [4, 1],
      indicator: 'sucursal_pregunta_18'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Las instalaciones de la sucursal (limpieza, espacio, temperatura)',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      size: [4, 1],
      indicator: 'sucursal_pregunta_19'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'El tiempo en que se llevó a cabo mi trámite / consulta.',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      size: [4, 1],
      indicator: 'sucursal_pregunta_20'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Visitas Concluido el trámite',
      helpText: '¿Cuántas veces acudiste a Sucursal para concluir tu trámite?',
      size: [2, 2],
      indicator: 'sucursal_pregunta_28',
      extraConfigs: {
        colors: PRODUTURO_SCALE_5_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Visitas Sin concluir el trámite',
      helpText: '¿Cuántas veces has acudido para darle seguimiento a tu trámite?',
      size: [2, 2],
      indicator: 'sucursal_pregunta_29',
      extraConfigs: {
        colors: PRODUTURO_SCALE_5_COLORS,
      }
    }),
    /* getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Visitas Concluido el trámite',
      helpText: '¿Cuántas veces acudiste a Sucursal para concluir tu trámite?',
      position: [0, 27],
      size: [4, 1],
      indicator: 'sucursal_pregunta_28',

    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Visitas Sin concluir el trámite',
      helpText: '¿Cuántas veces has acudido para darle seguimiento a tu trámite?',
      position: [0, 28],
      size: [4, 1],
      indicator: 'sucursal_pregunta_29',

    }),*/

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de Sentimientos',
      size: [4, 4],
      indicator: 'SENTIMENT'
    }),
    getNpsPorEjecutivoTable({
      size: [4, 2],
      criticalMomentInclude: [13604]
    })

  ]
} as DasboardConfig
