import { GENERAL_INDICATORS, PRODUTURO_SCALE_3_COLORS_SMOKED, getNpsPorEjecutivoTable } from '../commonWidgetsProfuturo'
import Color from 'color'

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'NPS',
      size: [4, 2],
      config: {
        id: 'satisfaccion-nps-general-byGeo',
        type: 'dataTable',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        groupName: 'Campaña',
        indicators: [
          {
            indicator: 'NPS_GENERAL',
            label: 'group',
            value: 'value',
            grouped: 'formattedMonth,criticalMoment',
            singleton: true,
            keyExtract: ['group', 'value', 'groupName', 'group_0', 'group_1'],
            extras: {
              periods: 10
            }
          }
        ]
      }
    },
    {
      title: 'Indicadores generales',
      helpLink: '--',
      size: [4, 2],
      config: {
        id: 'general-indicators',
        type: 'dataTable',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        groupName: 'Campaña',
        mapper: (data: any): any => {
          //console.log('general-indicators')
          //console.log(data)
          const values = data.map((item: any) => {
            return ['ATENCION_GENERAL', 'CSAT_GENERAL', 'FACILIDAD_GENERAL',
              'NPS_GENERAL', 'TIEMPO_ESPERA_GENERAL']
              .map((ind: string) => ({
                'group_1': item.criticalMomentDescription,
                'group_0': ind,
                groupName: GENERAL_INDICATORS[ind],
                value: item[ind]
              }))
          }).flat(1)
          //console.log(values)
          return values
        },
        indicators: [
          {
            indicator: 'NPS_GENERAL',
            url: 'reports/indicators-dashboard',
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: [
              'ATENCION_GENERAL',
              'CSAT_GENERAL',
              'FACILIDAD_GENERAL',
              'NPS_GENERAL',
              'TIEMPO_ESPERA_GENERAL',
              'criticalMoment',
              'criticalMomentDescription'
            ],
            extras: {
              //periods: 10,
              formula: 'nps-5',
              resultType: 'JSON'
            }
          }
        ]
      }
    },
    getNpsPorEjecutivoTable({
      size: [4, 2]
    }),
    /*{
      title: 'NPS por Ejecutivo',
      size: [4, 2],
      config: {
        id: 'satisfaccion-nps-general-by-ejecutivo',
        type: 'dataTable',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        groupName: 'Campaña',
        indicators: [
          {
            indicator: 'NPS_GENERAL',
            key: 'nps-general-ejecutivo-profuturo',
            label: 'group',
            value: 'value',
            grouped: 'formattedMonth,responseValue',
            groupByResponseValueId: 173002100,
            singleton: true,
            keyExtract: ['group', 'value', 'groupName', 'group_0', 'group_1'],
            extras: {
              periods: 10,
              groupNameFormat: "description"
            }
          }
        ]
      }
    },*/
    {
      title: 'Comentarios',
      size: [4, 2],
      config: {
        id: 'WORD_COUNT',
        type: 'wordCloud',
        indicators: [
          {
            indicator: 'WORD_COUNT',
            keyExtract: ['count', 'value', 'group'],
            singleton: true
          }
        ]
      }
    },
    {
      title: 'Análisis de Sentimientos',
      config: {
        id: 'text-analysis',
        type: 'textAnalysis',
        colors: PRODUTURO_SCALE_3_COLORS_SMOKED,
        /* groupLabels:  { positive: 'Positivos',
                 neutral: 'Neutros',
                 negative: 'Negativos',},
       */
        groupKeys: ['positive', 'neutral', 'negative'],
        indicators: [{
          indicator: '999',
          keyExtract: ['categories'],
          grouped: 'tag',
          value: 'value',
          singleton: false,
          extras: {}
        }]
      },
      size: [4, 4]
    }
  ]
} as DasboardConfig