import useAccount from '@hooks/useAccount'
import parseFilters from '@utils/parseFilters'
import parseFiltersIndicators from '@utils/parseFiltersIndicators'
import RestApiBuilder from '@utils/RestApiBuilder'
import { ApisauceInstance } from 'apisauce'

const getFileExtension = (file: any) => {
  const fileName = file.name
  return fileName.substr(fileName.lastIndexOf('.'))
}

/**
 * Expose all endponts for 'Alerts'.
 */
export default <T>(api: ApisauceInstance): ApiObject => ({
  ...RestApiBuilder<T>('alerts', api),
  comments: (alertId: number) => api.get(`alerts/${alertId}/comments`),
  responses: (id: number) => api.get(`survey-responses/${id}`),
  counters: (filters: FiltersUI) => {
    return api.post(
      `indicators/alert-counters/values`,
      {
        ...parseFiltersIndicators(filters),
        groupBy: "alertStatus"
      }
    )
  },
  addEvidence: (alertId: number, data: any[]) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    const formData = new FormData()
    data.forEach((file) => {
      const blob = new Blob([file.originFileObj], { type: file.type })
      formData.append('files', blob, file.name)
    })
    return api.post(`alert-evidence/${alertId}`, formData, config)
  },
  getEvidence: (id: number) => {
    return api.get(`alert-evidence/${id}`)
  },
  deleteEvidence: (id: number) => {
    return api.delete(`alert-evidence/${id}`)
  },
  stats: (filters: FiltersUI) =>
    api.get('alerts/dashboard', { ...parseFilters(filters) }),
})
