import React from 'react'
import { useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import modules from '@modules'
import useActions from '@hooks/useActions'

import './EpsilonFilterBar.less'
import useAccount from '@hooks/useAccount'
import useClientConfig from 'config/clients/useClientConfig'

type PropsType = {
  filtersData: Record<string, any>
  filters: FiltersUI
}

type EventValue<DateType> = DateType | null
type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null

const DatePicker = generatePicker<dayjs.Dayjs>(dayjsGenerateConfig)

/**
 * FilterBar
 */
export const EpsilonFilterBar = ({ filters, }: PropsType): JSX.Element => {
  const { userProfiles } = useAccount()
  const { includesFilterDate } = useClientConfig()

  const [localFilters, setLocalFilters,] = useState<DateFiltersUI>(filters)
  const [dateRangeOpen, setDateRangeOpen,] = useState<boolean>(false)
  const dispatchers = useActions(modules.actions.appData, [])
  const [rerender, setRerender] = useState<number>(1)

  //console.log('Rendering epsilon filters')
  //console.log(userProfiles)

  React.useEffect(() => {
    if (!dateRangeOpen) {
      setTimeout(() => {
        dispatchers.setFilters({
          ...filters,
          ...localFilters,
        })
      }, 500)
    }
  }, [dateRangeOpen,])

  React.useEffect(() => {
    setRerender(rerender + 1)
  }, [filters,])

  const _handleDateFilters = (dates: RangeValue<dayjs.Dayjs>) => {
    if (!dates || !dates[0] || !dates[1]) {
      return
    }

    const isoDates = dates?.map((date) => date?.format('YYYY-MM-DD'))

    setLocalFilters({
      //...localFilters,
      fromDate: isoDates[0] ? isoDates[0] : localFilters.fromDate,
      toDate: isoDates[1] ? isoDates[1] : localFilters.toDate,
    })
  }

  return (
    <div className='filter-bar-component-container'>
      {
        includesFilterDate(userProfiles)
        && <DatePicker.RangePicker
          /*defaultValue={[
            dayjs(filters.fromDate, 'YYYY-MM-DD'),
            dayjs(filters.toDate, 'YYYY-MM-DD'),
          ]}*/
          value={[
            dayjs(filters.fromDate, 'YYYY-MM-DD'),
            dayjs(filters.toDate, 'YYYY-MM-DD'),
          ]}
          allowClear={false}
          style={{ width: 250, maxHeight: 35, boxShadow: "none" }}
          className='lmt-filter-bar-date-picker'
          size='small'
          format='DD/MM/YYYY'
          onChange={_handleDateFilters}
          onOpenChange={setDateRangeOpen}
        />
      }
    </div>
  )
}
