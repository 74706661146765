import api from '@services/api'
import { ActionCreatorsMapObject } from 'redux'
import { createModule, EffectParam, STATUS } from 'redux-acron'
import { all, call, put, SagaReturnType } from 'redux-saga/effects'
import authModule from './AuthModule'
import useClientConfig from 'config/clients/useClientConfig'

export type AccountState = {
  data: UserAccount | null
  loadingInfo: boolean
  loadingPasswordReset: boolean
  passwordReseted: boolean
  passwordResetedError: boolean
}

const AccountModule = createModule<AccountState>({
  name: 'account',
  state: {
    data: null,
    loadingInfo: false,
    loadingPasswordReset: false,
    passwordReseted: false,
    passwordResetedError: false,
  },
  handlers: {
    getInfo: (state, { payload, status = STATUS.PENDING_STATUS }) => {
      if (status === STATUS.PENDING_STATUS) {
        return state.merge({
          loadingInfo: true,
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          loadingInfo: false,
          data: payload.data,
        })
      }

      return state
    },

    changePassword: (state, { status = STATUS.PENDING_STATUS }) => {
      if (status === STATUS.PENDING_STATUS) {
        return state.merge({
          loadingPasswordReset: true,
          passwordReseted: false,
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          loadingPasswordReset: false,
          passwordReseted: true,
        })
      }

      if (status === STATUS.FAILURE_STATUS) {
        return state.merge({
          loadingPasswordReset: false,
          passwordResetedError: true,
        })
      }

      return state
    },

    [authModule.actionsTypes.signin]: (
      state,
      { payload, status = STATUS.PENDING_STATUS }
    ) => {
      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          data: payload.data,
        })
      }
      return state
    },
  },

  effects: {
    getInfo: {
      *callback(actions: ActionCreatorsMapObject) {
        const {
          useCriticalMoments,
          useClusters,
          useDynamicReports,
          useGeoLocations,
          useLogicalLocations,
          useWorkflows,
          useChannels
        } = useClientConfig()

        type Responses = [
          SagaReturnType<typeof api.account.getInfo>,
          SagaReturnType<typeof api.criticalMoments.list>,
          SagaReturnType<typeof api.logicalDistributions.list>,
          SagaReturnType<typeof api.geoDistributions.list>,
          SagaReturnType<typeof api.clusters.list>,
          SagaReturnType<typeof api.dynamicReportsFields.getFields>,
          SagaReturnType<typeof api.workflows.list>,
          SagaReturnType<typeof api.channels.list>
        ]

        const responses: Responses = yield all([
          api.account.getInfo(),
          useCriticalMoments()
            ? api.criticalMoments.list(0, 1000)
            : { data: [], ok: true },
          useLogicalLocations()
            ? api.logicalDistributions.list(0, 1000)
            : { data: [], ok: true },
          useGeoLocations()
            ? api.geoDistributions.list(0, 1000)
            : { data: [], ok: true },
          useClusters()
            ? api.clusters.list(0, 1000)
            : { data: [], ok: true },
          useDynamicReports()
            ? api.dynamicReportsFields.getFields(0, 1000)
            : { data: [], ok: true },
          useWorkflows()
            ? api.workflows.list(0, 1000)
            : { data: [], ok: true },
          useChannels()
            ? api.channels.list(0, 1000)
            : { data: [], ok: true },
        ])

        const resultOk = responses.reduce((result, value) => {
          result = result && value !== undefined && value.ok
          return result
        }, true)

        if (resultOk) {

          yield put(
            actions.getInfo(
              {
                data: {
                  ...responses[0].data,
                  criticalMoments: responses[1].data ?? [],
                  logicalLocations: responses[2].data,
                  geoLocations: responses[3].data ?? [],
                  clusters: responses[4].data,
                  dynamicReportsFields: responses[5].data ?? [],
                  workflows: responses[6].data ?? [],
                  channels: responses[7].data ?? [],
                },
              },
              STATUS.SUCCESS_STATUS
            )
          )
        } else {
          yield put(actions.getInfo({}, STATUS.FAILURE_STATUS))
          yield put(authModule.actionCreators.signout({}))
        }
      },
    },

    changePassword: {
      *callback(
        actions: ActionCreatorsMapObject,
        { payload }: EffectParam<{ oldPassword: string; newPassword: string }>
      ) {
        const response: {
          ok: boolean
        } = yield call(
          api.account.changePassword,
          payload.oldPassword,
          payload.newPassword
        )

        if (response.ok) {
          yield put(actions.getInfo())
          yield put(actions.changePassword({}, STATUS.SUCCESS_STATUS))
        } else {
          yield put(actions.changePassword({}, STATUS.FAILURE_STATUS))
        }
      },
    },
  },
})

export default AccountModule
