import { filterGeos } from '@utils/GeoUtils'
import { getCommonWidget } from '../dashWidgetHelper'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'
import { commonWidgets } from './commonWidgetsBenavides'

export default {
  title: 'General Dashboard',
  description: 'Dashboard General',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getLineDetailedRadar({
      mainIndicator: 'generalpregunta_1',
      radialIndicators: [
        'generalpregunta_1',
        'generalpregunta_3',
        'ATRIB_XP_FARM_AMB_INT',
        'ATRIB_XP_FARM_AMB_EXT',
        'ATRIB_XP_FARM_SATIF_VAR'
      ],
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 3],
      title: 'Satisfacción General',
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        initialVisibleChart: 1,
        maxValue: 100
      },
      criticalMomentInclude: [9953],
      indicatorsNames: {
        'ATRIB_XP_FARM_AMB_INT': 'Ambiente interior',
        'ATRIB_XP_FARM_AMB_EXT': 'Ambiente exterior',
        'ATRIB_XP_FARM_SATIF_VAR': 'Satisfacción variedad',
        'generalpregunta_3': 'NPS',
        'generalpregunta_1': 'Satisfacción'
      },
    }),

    //2
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [9953],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [9953],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsTrendByMonth',
      subTitle: 'widgets.alertsCountMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: 9953,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsTrendByWeek',
      subTitle: 'widgets.alertsCountWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: 9953,
    }),

    //3
    {
      title: 'Análisis de sentimientos',
      type: 'label',
      position: [0, 6],
      size: [4, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      position: [0, 7],
      size: [4, 4],
      indicator: 'SENTIMENT',
    }),

    //4
    {
      title: 'Regiones',
      type: 'label',
      position: [0, 11],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de regiones',
      position: [0, 12],
      size: [2, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [9953],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'Top 5 Mejores regiones',
      position: [2, 12],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
      },
      indicator: 'generalpregunta_1',
      criticalMomentInclude: [9953],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'Top 5 regiones a mejorar',
      position: [3, 12],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#db7476',
      },
      indicator: 'generalpregunta_1',
      criticalMomentInclude: [9953],
    }),

    //5
    {
      title: 'Diagrama de Ishikawa',
      type: 'label',
      position: [0, 14],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      //title: 'Diagrama Ishikawa',
      position: [0, 15],
      size: [4, 2],
      indicator: 'sentiment',
      criticalMomentInclude: [9953],
    }),

    //6
    {
      title: 'Palabras',
      type: 'label',
      position: [0, 17],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 18],
      size: [4, 2],
      indicator: 'WORD_COUNT',
    }),
  ],
}
