import { DEFAULT_COLORS, DEFAULT_SCALE_3_COLORS } from "@components/widgets/apacheEchart/Commons";
import { PRODUTURO_SCALE_3_COLORS_SMOKED, PROFUTURO_GREEN_1, PROFUTURO_ORANGE, PROFUTURO_RED_1 } from "../profuturo/commonWidgetsProfuturo";
import Color from "color";

export const widgetsSentimentAnalysis: CommonWidget = {


  /**************************************************************************
   *                      SENTIMENT ANALISYS
  ***************************************************************************/

  textAnalysis: {
    title: 'Análisis de sentimientos',
    position: [0, 0],
    size: [4, 4],
    config: {
      id: 'text-analysis',
      type: 'textAnalysis',
      colors: DEFAULT_SCALE_3_COLORS,
      indicators: [
        {
          indicator: 'sentiment',
          grouped: 'tag',
          keyExtract: ['categories', 'count'],
          singleton: false,
          value: 'value',
          extras: {},
        },
      ],
    },
  },
}
