import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

const getCJIndicatorItems = (data: any, indicatorName: string, pos: number = 0) => {
    if (data && data[indicatorName]?.data) {
        return data[indicatorName].data.map((item: any, idx: number) => ({
            key: idx + pos,
            number: (pos + idx + 1).toString(),
            title: item.group,
            items: [
                { order: 1, name: '', value: item.value }
            ]
        }));
    }
    return [];
};

export const BENAVIDES_CJ_ORDER = {
    // Venta
    10155: 0, // Ambiente interior
    12151: 1, // Ambiente exterior
    10156: 2, // Personal
    10051: 3, // Satisfacción geeral
    10053: 4, // NPS
  }

export const omoikaneBenavides: OmoikaneConfig = {
    decimals: 2,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP,
        //OmoikaneTab.CLUSTERS
    ],
    monthdsCoverage: 2,
    indicators: [
        {
            indicator: 'GENERAL_CUSTOMER_JOURNEY',
            key : 'GENERAL_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-enc-general',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'generalpregunta_1',
            key: 'generalpregunta_1',
            keyExtract: [],
            extras: {
                omoikane: true,
                groupNameFormat: "description",
            }
        },
        {
            indicator: 'generalpregunta_3',
            key: 'generalpregunta_3',
            keyExtract: [],
            extras: {
                omoikane: true,
                groupNameFormat: "description",
            }
        },
        {
            indicator: 'banregio_pregunta_4',
            key: 'banregio_pregunta_4',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description",
            }
        },
        {
            indicator: 'banregio_pregunta_5',
            key: 'banregio_pregunta_5',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description",
            }
        },

    ],
    sentiment: {
        indicators: {
            General: 'sentiment-enc-general',
            Banregio: 'sentiment-enc-general'
            
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            General: [
                { order: 1, name: 'NPS', key: 'banregio_pregunta_5', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'banregio_pregunta_4', widgetType: 'gauge' },
            ],
            Banregio: [
                { order: 1, name: 'NPS', key: 'banregio_pregunta_5', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'banregio_pregunta_4', widgetType: 'gauge' },
            ]
           
        }
    },
    clusters: {
        General: {
            counters: { indicator: 'survey-count-enc-general' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'generalpregunta_1',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'generalpregunta_3',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                            criticalMomentInclude: [1]
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                            criticalMomentInclude: [1]
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'SURVEY_COUNT',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    },
                    {
                        id: 'alerts-counters',
                        indicator: 'ALERT_COUNTERS',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    }
                ]
            }
        }
    },
    customerJourney: {
        General: (data: any) => {
            const result = [
                ...getCJIndicatorItems(data, 'banregio_pregunta_4', 0),
                ...getCJIndicatorItems(data, 'banregio_pregunta_5', 1)
            ]
            return result
        },
        Banregio: (data: any) => {
            const result = [
                ...getCJIndicatorItems(data, 'banregio_pregunta_4', 0),
                ...getCJIndicatorItems(data, 'banregio_pregunta_5', 1)
            ]
            return result
        }

    }
}
