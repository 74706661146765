import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/channels',
  layout: true,
  getComponent: async () => {
    const ChannelsLayout = await import(
      /* webpackChunkName: "channels-modules" */ './components/ChannelsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "channels-modules" */ './ChannelsModule'
    )

    injectReducer(store, { key: 'channels', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: ChannelsLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'channels.list_title',
      getComponent: async () => {
        const ChannelsListView = await import(
          /* webpackChunkName: "channels-modules" */ './components/ChannelsListView'
        )
        return {
          children: ChannelsListView.default,
        }
      },
    },

    {
      title: 'channels.new_title',
      path: '/new',
      getComponent: async () => {
        const ChannelsFormView = await import(
          /* webpackChunkName: "channels-modules" */ './components/ChannelsFormView'
        )
  
        return {
          children: ChannelsFormView.default,
        }
      },
    },

    {
      title: 'channels.new_title',
      path: '/edit/:id',
      getComponent: async () => {
        const ChannelsFormView = await import(
          /* webpackChunkName: "channels-modules" */ './components/ChannelsFormView'
        )
  
        return {
          children: ChannelsFormView.default,
        }
      },
    },
  ],
})
