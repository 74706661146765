
export const widgetsGauge: CommonWidget = {
  gaugeGeneric: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'gauge-generic',
      type: 'gauge',
      fillMode: '',
      color: '#33b9ce',
      showValues: true,
      showHistoric: false,
      maxValue: 10,
      decimals: 1,
      center: ['50%', '50%'],
      radius: '90%',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'count', 'value'],
          value: 'value',
          extras: {},
        },
      ],
    },
  },

  gaugeGenericByStatus: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'gauge-generic-by-status',
      type: 'gauge',
      color: '#33b9ce',
      showHistoric: false,
      center: ['50%', '50%'],
      radius: '90%',
      indicators: [
        {
          indicator: 'FALTA',
          //url: 'FALTA',
          grouped: 'status',
          dataType: 'list',
          keyExtract: ['group', 'count'],
          label: 'group',
          value: 'count',
          singleton: true,
          extras: {},
        },
      ],
    },
  },
  multipleGaugeByQuestion: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'multiple-gauge-by-question',
      type: 'multipleGauge',
      fillMode: '',
      //colors: pieColors,
      showValues: true,
      showHistoric: false,
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'question',
          keyExtract: ['group', 'value', 'groupId'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
}
