import { getCommonWidget } from "../dashWidgetHelper"
import { bupaStakedBarDetailedByMonthMapper, commonWidgets } from "./commonWidgetsBupa"



const getBupaWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => [

  getCommonWidget({
    commonWidgets: commonWidgets,
    //name: 'barDetailedByMonthAndGeo',
    name: 'barByMonth',
    title: 'NPS General por mes',
    size: [3, 3],
    // @ts-ignore
    mapper: bupaStakedBarDetailedByMonthMapper,
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
      titleAlign: 'center'
    },
    indicator: 'NPS',
    //render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29]),
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'semicircleGeneric',
    title: 'NPS',
    key: 'nps-gauge',
    size: [1, 3],
    indicator: 'NPS',
    historic: {
      type: 'line',
      formula: 'nps-10',
      theme: 'dark'
    },
    extraConfigs: {
      backgroundColor: '#5F2B87',
      fontColor: 'white',
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    size: [4, 4],
    indicator: 'SENTIMENT'
  })
]

export default {
  title: 'Bupa Dashboard usuarios externos',
  description: 'Bupa Dashboard usuarios externos',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI, filtersData: Record<string, any>) => getBupaWidgets(filters, filtersData),
} as DasboardConfig
