import parseFilters from '@utils/parseFiltersIndicators'
import parseLcmFilters from '@utils/parseLcmFilters'
import RestApiBuilder from '@utils/RestApiBuilder'
import { ApisauceInstance } from 'apisauce'
import useClientConfig from 'config/clients/useClientConfig'

const parseExtraFilters = (extras: any) => {
  var extraFilters = extras
    ? Object.keys(extras)
      .filter((key) => key !== 'criticalMomentId')
      .reduce((obj, key) => {
        const value = extras[key]
        if (value !== undefined && value !== null) {
          return Object.assign(obj, { [key]: value, })
        }

        return obj
      }, {})
    : extras


    if (extras?.status === 'EXTERNAL_SOURCE') {
      extraFilters = {
        ...extraFilters,
        criticalMomentInclude: undefined
      }
    }

    return extraFilters
}

/**
 * Expose all endponts for 'reports'.
 */
export default <T>(vocApi: ApisauceInstance, lcmApi: ApisauceInstance): ApiObject => ({
  ...RestApiBuilder<T>('indicators', vocApi),
  data: (url: string, filters: any, filtersData: Record<string, any>, extras: any) => {
    //console.log('API get data')
    //console.log(filters)
    //console.log(extras)
    const useLcmApi = extras !== undefined
      && extras.useLcmApi !== undefined
      && extras.useLcmApi

    if (useLcmApi) {
      let lcmFilters = {
        ...parseLcmFilters(filters, filtersData, extras)
      }

      if (url === 'dashboard/status') {
        delete lcmFilters.geoLocations
      }

      return lcmApi.get(url, lcmFilters)
    }

    var finalFilters = {
      ...parseFilters(filters, extras),
      ...parseExtraFilters(extras),
    }

    return vocApi.post(url, finalFilters)
  },
  comments: (tag: string, filters: FiltersUI, extras: any) => {
    const { getSentimentCommentsIndicatorName } = useClientConfig()
    //console.log('API get comments')
    //console.log(extras)
    return vocApi.post('indicators/' + getSentimentCommentsIndicatorName() + '/values', {
      ...parseFilters(filters, extras),
      ...parseExtraFilters(extras),
      tag,
    })
  },
})
