import { Icon } from '@ant-design/compatible'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import useConnection from '@hooks/useConnection'
import { Dropdown, Menu, Typography } from 'antd'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useClientTranslation from 'config/clients/useClientTranslation'
import classnames from 'classnames'
import useFilters from '@hooks/useFilters'
import useActions from '@hooks/useActions'
import modules from '@modules'

import './EpsilonMenuDropdown.less'

const EpsilonMenuDropdown: React.FC = () => {
  const { translate } = useClientTranslation()
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const { menuItems } = useConnection<{
    menuItems: Array<MenuType>
  }>(['appData.menuItems'])
  const firstObjectMenu = menuItems.find(
    (item) => item.path === location.pathname
  )
  const [selected, setSelected] = useState<MenuType>(
    firstObjectMenu ?? menuItems[0]
  )
  const { filters } = useFilters()
  const dispatchers = useActions(modules.actions.appData, [])

  const changeMenuItem = (item: any) => {
    setSelected(item)
    dispatchers.getSurveyCount({filters: filters})
  }

  const menu = () => {
    return (
      <>
        <div className='epsilon-menu-dropdown'>{trigger(true)}</div>

        <span className='menu-title'>Secciones VoC</span>
        <Menu className='menu' onClick={() => setVisible(false)}>
          {menuItems.map((item) => (
            <Menu.Item
              key={item.path}
              onClick={() => changeMenuItem(item)}
              className='menu-item'
            >
              <Link className='menu-link' to={item.path}>
                {typeof item.icon === 'string' ? (
                  <div className='icon-container'>
                    <Icon
                      theme='outlined'
                      type={translate(item.icon)}
                      style={{ fontSize: 20, color: '#7B7B7B' }}
                    />
                  </div>
                ) : (
                  <div className='icon-container'>{item.icon}</div>
                )}

                <span>{translate(item.label)}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </>
    )
  }

  const trigger = (open: boolean = false) => (
    <a onClick={(e) => setVisible(false)}>
      <div
        className={classnames('epsilon-menu-dropdown-select', {
          fluid: selected?.fluid && !open,
        })}
      >
        {typeof selected?.icon === 'string' ? (
          <Icon
            theme='outlined'
            type={translate(selected?.icon)}
            style={{ fontSize: 20, color: '#7B7B7B' }}
          />
        ) : (
          selected?.icon
        )}

        <div className='epsilon-menu-focus-styled'>
          <Typography className='general-title-menu-dropdown'>
            {translate('menu.menu')}
          </Typography>
          <Typography className='general-text-menu-dropdown'>
            {translate(selected?.label)}
          </Typography>
        </div>
        <CaretDownOutlined style={{ color: '#7967AB' }} />
      </div>
    </a>
  )

  return (
    <Dropdown
      overlay={menu}
      className={classnames('epsilon-menu-dropdown', {
        fluid: selected?.fluid,
      })}
      trigger={['click']}
      overlayClassName={
        visible ? 'epsilon-menu-container-open' : 'epsilon-menu-container'
      }
      openClassName='open'
      onVisibleChange={setVisible}
      visible={visible}
    >
      {trigger(false)}
    </Dropdown>
  )
}

export default EpsilonMenuDropdown
