
import logoBupa from '@assets/logos/bupa-logo.png'
import logoBupaSmall from '@assets/logos/bupa-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getBupaFilterCriticalMoments } from '../dashboard/dashHelper'
import deafultHelpDefinition from '../help/defaultHelp'
import { SurveysCountInfo } from '@modules/AppDataModule'

export const bupaConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://bupa-backend-voc.limetropy.com/api/',
    lcmBackendUrl: 'https://bupa-backend-core.limetropy.com/api/ui/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: '/voc',
    logoSmall: logoBupaSmall,
    logo: logoBupa,
    filtersFromDateSubstract: 1,
    filtersToDateSubstract: 0,
    filters: [
        FILTER_TYPES.DATES,
        FILTER_TYPES.GEOS,
        FILTER_TYPES.LOGICALS,
        FILTER_TYPES.CRITICAL_MOMENTS,
        FILTER_TYPES.CHANNELS
    ],
    supportsInternationalization: false,
    msExcelReportExt: 'xlsx',
    decimals: 0,
    menuDashboards: [DASHBOARDS.DEFAULT],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getBupaFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    geosRootId: 17101,
    logicalsRootId: 19713,
    criticalMomentsRootId: 18129,
    criticalMomentFilterType: 'tree',
    criticalMomentMultipleSelect: true,
    actionPlans: {
        geoLeafLevel: '',
        useWorstTags: true
    },
    omoikane: {
        decimals: 2,
        enabledTabs: [],
        monthdsCoverage: 2,
        indicators: [],
        sentiment: {
            indicators: {},
            properties: {
                sentiment: '',
                percentage: '',
                sentimentLocal: '',
                percentageLocal: ''
            }
        },
        customerJourney: {},
        cxRay: { indicators: {} },
        clusters: {},
    },
    useBackDashboards: false,
    useDashboardDynamicPosition: true,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.LOGICALS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS,
        METADADATA_LOAD.CHANNELS,
        //METADADATA_LOAD.WORKFLOWS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.bupa,
        default: DASHBOARDS_CONFIGS.bupa[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: 'post',
        tableColumns: undefined,
        tabs: [
            SurveyResponsesTab.ANSWERED,
            SurveyResponsesTab.IN_PROGRESS
        ],
        surveysCountersValue: (countersInfo: SurveysCountInfo) => {
            //console.log('get surveys counters value!')
            //console.log(countersInfo)

            return countersInfo?.answered + countersInfo?.inProgress
        }
    },
    alerts: {
        alertHeaderFields: [
            {
                id: 10,
                dataIndex: 'aut',
                key: 'aut',
                title: 'Contactar',
                width: 50,
                align: 'center',
                //enableSearch: true,
                render: (value: any, record: any) => {
                    const humanSurvey = JSON.parse(record?.humanSurvey)
                    return humanSurvey !== undefined
                        ? (humanSurvey?.['AUT']?.formated ?? humanSurvey?.['AUT']?.value)
                        : '-'
                }
            },
            {
                id: 11,
                dataIndex: 'criticalMomentName',
                key: 'criticalMomentName',
                title: 'Momento critico',
                width: 80,
                align: 'center'
            },
            {
                id: 12,
                dataIndex: 'geoLocationName',
                key: 'geoLocationName',
                title: 'Segmento',
                width: 80,
                align: 'center'
            }
        ]
    },
    notificationsConfig: {
        enabled: true
    },
    help: deafultHelpDefinition
}
