import { isEmpty } from 'lodash'
import { SECTION_KEYS } from './perms'
import icons from '@utils/icons'
import { ReactNode } from 'react'
import useClientConfig from 'config/clients/useClientConfig'

export const PAGE_NAMES = {
  // Super Admin default page
  COMPANIES: 'COMPANIES',

  // Admin user pages
  SURVEYS: 'SURVEYS',
  LOGICAL_DISTRIBUTIONS: 'LOGICAL_DISTRIBUTIONS',
  GEOGRAPHIC_DISTRIBUTIONS: 'GEOGRAPHIC_DISTRIBUTIONS',
  CRITICAL_MOMENTS: 'CRITICAL_MOMENTS',
  CHANNELS: 'CHANNELS',
  ROLES: 'ROLES',
  DASHBOARD_BUILDER: 'DASHBOARD_BUILDER',
  USERS: 'USERS',

  // Normal user pages
  DASHBOARD: 'DASHBOARD',
  SURVEY_STATS: 'SURVEY_STATS',
  SOCIAL_LISTENING: 'SOCIAL_LISTENING',
  ALERTS_STATS: 'ALERTS_STATS',
  ALERTS_LIST: 'ALERTS_LIST',
  ACTION_PLANS: 'ACTION_PLANS',
  REPORTS: 'REPORTS',
  EXTENDED_DASHBOARD: 'EXTENDED_DASHBOARD',
  OMOIKANE_AI: 'OMOIKANE_AI',
  POWER_INTELLIGENCE: 'POWER_INTELLIGENCE',
  CLIENT_ACCOUNTS: 'CLIENT_ACCOUNTS'
}

type PageMenu = {
  icon: string | ReactNode
  label: string
  path: string
  key: string
  access: string
  action?: string
  order: number
  fluid?: boolean
}

export const PAGES_CONFIG: Record<string, PageMenu> = {
  [PAGE_NAMES.COMPANIES]: {
    icon: 'Shop',
    label: 'menu.Companies',
    path: '/admin/companies',
    key: 'companies',
    access: 'companies',
    order: 1,
  },

  [PAGE_NAMES.LOGICAL_DISTRIBUTIONS]: {
    icon: 'Apartment',
    label: 'menu.Logical_Distributions',
    path: '/admin/logical-distributions',
    key: SECTION_KEYS.LOGICAL_DISTRIBUTIONS,
    access: SECTION_KEYS.LOGICAL_DISTRIBUTIONS,
    order: 2,
  },

  [PAGE_NAMES.GEOGRAPHIC_DISTRIBUTIONS]: {
    icon: 'Environment',
    label: 'menu.Grographic_Distributions',
    path: '/admin/geo-distributions',
    key: SECTION_KEYS.GEOGRAPHIC_DISTRIBUTIONS,
    access: SECTION_KEYS.GEOGRAPHIC_DISTRIBUTIONS,
    order: 3,
  },

  [PAGE_NAMES.CRITICAL_MOMENTS]: {
    icon: 'Warning',
    label: 'menu.Critical_Moments',
    path: '/admin/critical-moments',
    key: SECTION_KEYS.CRITICAL_MOMENTS,
    access: SECTION_KEYS.CRITICAL_MOMENTS,
    order: 4,
  },

  [PAGE_NAMES.CHANNELS]: {
    icon: 'Warning',
    label: 'menu.Channels',
    path: '/admin/channels',
    key: SECTION_KEYS.CHANNELS,
    access: SECTION_KEYS.CHANNELS,
    order: 5,
  },

  [PAGE_NAMES.ROLES]: {
    icon: 'DeploymentUnit',
    label: 'menu.Roles',
    path: '/admin/roles',
    key: 'roles',
    access: 'roles',
    order: 6,
  },

  [PAGE_NAMES.USERS]: {
    icon: 'Team',
    label: 'menu.Users',
    path: '/admin/users',
    key: 'users',
    access: 'users',
    order: 7,
  },

  [PAGE_NAMES.SURVEYS]: {
    icon: icons.surveys,
    label: 'menu.Surveys',
    path: '/surveys',
    key: SECTION_KEYS.SURVEYS,
    access: SECTION_KEYS.SURVEYS,
    action: 'main',
    order: 8,
  },
  [PAGE_NAMES.DASHBOARD]: {
    icon: icons.dashboard,
    fluid: true,
    label: 'menu.Dashboard',
    path: '/dashboard',
    key: SECTION_KEYS.DASHBOARD,
    access: SECTION_KEYS.DASHBOARD,
    order: 1,
  },

  [PAGE_NAMES.EXTENDED_DASHBOARD]: {
    icon: 'AreaChart',
    fluid: true,
    label: 'menu.DashboardExtended',
    path: '/extended-dashboard',
    key: SECTION_KEYS.EXTENDED_DASHBOARD,
    access: SECTION_KEYS.EXTENDED_DASHBOARD,
    order: 2,
  },

  [PAGE_NAMES.SOCIAL_LISTENING]: {
    icon: 'AreaChart',
    label: 'menu.Social_Listening',
    path: '/social-listening',
    key: SECTION_KEYS.SOCIAL_LISTENING,
    access: SECTION_KEYS.SOCIAL_LISTENING,
    order: 5,
  },

  [PAGE_NAMES.SURVEY_STATS]: {
    icon: icons.surveys,
    label: 'menu.Survey_Stats',
    path: '/survey-stats',
    key: SECTION_KEYS.SURVEY_STATS,
    access: SECTION_KEYS.SURVEY_STATS,
    order: 6,
  },

  [PAGE_NAMES.CLIENT_ACCOUNTS]: {
    icon: icons.statistics,
    label: 'menu.Client_Accounts',
    path: '/client-accounts',
    key: SECTION_KEYS.CLIENT_ACCOUNTS,
    access: SECTION_KEYS.CLIENT_ACCOUNTS,
    order: 6,
  },

  [PAGE_NAMES.ALERTS_STATS]: {
    icon: icons.alerts,
    label: 'menu.Alerts_Stats',
    path: '/alerts/stats',
    key: `${SECTION_KEYS.ALERTS}/stats`,
    access: SECTION_KEYS.ALERTS,
    action: 'stats',
    order: 7,
  },

  [PAGE_NAMES.ALERTS_LIST]: {
    icon: icons.alerts,
    label: 'menu.Alerts_List',
    path: '/alerts/list',
    key: SECTION_KEYS.ALERTS,
    access: SECTION_KEYS.ALERTS,
    order: 8,
  },

  [PAGE_NAMES.OMOIKANE_AI]: {
    icon: icons.iaMotor,
    label: 'Omoikane AI',
    path: '/omoikane-ai',
    key: SECTION_KEYS.OMOIKANE_AI,
    access: SECTION_KEYS.OMOIKANE_AI,
    order: 9,
  },

  [PAGE_NAMES.POWER_INTELLIGENCE]: {
    icon: icons.statistics,
    label: 'Power Intelligence',
    path: '/power-intelligence',
    key: SECTION_KEYS.POWER_INTELLIGENCE,
    access: SECTION_KEYS.POWER_INTELLIGENCE,
    order: 10,
  },

  [PAGE_NAMES.ACTION_PLANS]: {
    icon: icons.actionPlans,
    label: 'menu.Action_Plans',
    path: '/action-plans',
    key: SECTION_KEYS.ACTION_PLANS,
    access: SECTION_KEYS.ACTION_PLANS,
    order: 11,
  },

  [PAGE_NAMES.REPORTS]: {
    icon: icons.reports,
    label: 'menu.Reports',
    path: '/reports',
    key: SECTION_KEYS.REPORTS,
    access: SECTION_KEYS.REPORTS,
    order: 12,
  },
}

export const getMenuItems = (
  authorities: Array<Authority>,
  pages: Record<string, string[]> // dahsboard, alerts, reports
): Array<PageMenu> => {
  if (authorities.indexOf('ROLE_ROOT') > -1) {
    return [PAGES_CONFIG.COMPANIES]
  }
  if (authorities.indexOf('ROLE_ADMIN') > -1) {
    const {
      useGeoLocations,
      useLogicalLocations,
      useCriticalMoments,
      useChannels
    } = useClientConfig()

    let basePages = [
      PAGES_CONFIG.USERS,
      PAGES_CONFIG.ROLES,
      // PAGES_CONFIG.DASHBOARD_BUILDER,
      // PAGES_CONFIG.SURVEYS
    ]

    basePages = useGeoLocations()
      ? [...basePages, PAGES_CONFIG.GEOGRAPHIC_DISTRIBUTIONS]
      : basePages

    basePages = useLogicalLocations()
      ? [...basePages, PAGES_CONFIG.LOGICAL_DISTRIBUTIONS]
      : basePages

    basePages = useCriticalMoments()
      ? [...basePages, PAGES_CONFIG.CRITICAL_MOMENTS]
      : basePages

    basePages = useChannels()
      ? [...basePages, PAGES_CONFIG.CHANNELS]
      : basePages

    return basePages
  }

  if (pages && !isEmpty(pages)) {
    const pageKeys = Object.keys(pages)
    return Object.keys(PAGES_CONFIG)
      .filter((p) => {
        const action = PAGES_CONFIG[p].action
        return (
          pageKeys.indexOf(PAGES_CONFIG[p].access) > -1 &&
          (action === 'main' ||
            !action ||
            (action && pages[PAGES_CONFIG[p].access].indexOf(action) > -1))
        )
      })
      .map((page) => {
        return PAGES_CONFIG[page]
      })
  }
  return []
}
