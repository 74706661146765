import distribuidorPosventa from './distribuidorPosventa'
import distribuidorVenta from './distribuidorVenta'
export default {
  title: 'Distribuidor Dashboard',
  description: 'Dashboard de Distribuidor',
  widgets: (filters: FiltersUI) => [
    ...distribuidorVenta.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
    })),
    ...distribuidorPosventa.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(2),
    })),
  ],
} as DasboardConfig
